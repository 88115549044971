import * as React from "react";
import { Switch, Route } from "react-router-dom";
import { withRouter, RouteComponentProps } from "react-router";
import Layout from "./Layout/Layout";
import Store from './store/store'
import HomePageUnconnected from './unconnected'
import ProtectedRoutes from "./ProtectedRoutes/ProtectedRoutes";
import ParametersUser from "./parametersUsers/ListUsers";
import Dashboard from "./dashboard/Dashboard"
import { observer } from "mobx-react";
import Form from "../components/FormulaireReferencement/Form";
import axios from "axios";
import cookie from 'react-cookies';
import * as jwt from "jsonwebtoken";
import Parameter from "./parameters/param";
import{ MuiThemeProvider, createMuiTheme} from '@material-ui/core/styles';
import AllTasksUsers from "./Workflow/AllTasksUsers";
import Models from "./parametersModels/ListContainer";
import Roles from "./parametersRoles/ListRoles";
import MyMessages from "./parametersMessages/MyMessages"
import Search from "./search/searchWorkflow";
import Me from "./Menus/Me"
import HistoricActions from "./Workflow/HistoricActions";
const stylesheet = require("../styles/styles.module.scss");
import parameterStartAuto from "./parametersAutoStart/parameterAutoStart";
import LoadingLogo from "./loadingLogo/LoadingLogo";
import SnackBar from "./SnackBar/SnackBar";
import refresh from "./refresh/refresh";
import Delegation from "./delegation/delegation";
import ListGroupes from "./parametersGroups/ListGroupes";
import FormsList from "./forms/formsList/FormsList";
import CreateForm from "./forms/createForm/CreateForm";
import FicheDePoste from "./forms/ficheDePoste/FicheDePoste";
import Email from "./Email/Email";
import { initializeIcons } from '@uifabric/icons';
import EmailContentEditor from "./Email/EmailContentEditor";
initializeIcons();

const ISSUER = "lyka-sso";
const secret: jwt.Secret = { key: "lydoc", passphrase: "yes secret" };
const CrossStorageClient = require('cross-storage').CrossStorageClient;


const theme = createMuiTheme({
  overrides: {
    // Name of the component ⚛️ / style sheet
    MuiInputLabel: {
      outlined: {
        transform: "translate(14px, 14px) scale(1)"
      },
      shrink: {
        transform: "translate(14px, -6px) scale(0.75)"
      }
    },
    MuiOutlinedInput: {
      input: {
        padding: "11px 14px"
      }
    },
    MuiTabs: {
      root: {
        minHeight: "auto"
      },
      indicator: {
        backgroundColor: Store.themeParam.color
      }
    },
    MuiTab: {
      root: {
        minHeight: "auto",
        minWidth: "auto !important",
        background: Store.themeParam.backgroundPrimary,
        color: Store.themeParam.color + " !important"
      }
    },
    MuiExpansionPanelDetails: {
      root: {
        padding: 0
      }
    },
    MuiExpansionPanelSummary: {
      root: {
        minHeight: "auto !important",
        backgroundColor: "#eee"
      },
      content: {
        margin: "0 !important"
      }
    },
    MuiToolbar: {
      regular: {
        minHeight: "55px !important"
      }
    }
  },
  palette: {
    primary: {
      main: Store.themeParam.backgroundSecondary,
      contrastText: Store.themeParam.color
    }
  },
  typography: {
  }
});
@observer
class App extends React.Component<RouteComponentProps, any> {
  public constructor(props: any) {
    super(props);
    this.state = {
      idDocUserConnected: undefined,
      redirect: false,
      redirectOk: false,

      keyNameSessionLink: "lyflowStartLink",
      loadingAuth: true
    }

    // Récupération de l'url avant authentification pour redirection après authentification
    // Mise en cache dans le SessionStorage
    let searchParams: string = this.props.location.search;
    if (searchParams.startsWith("?ssoToken=") == true) {
      searchParams = "";
    }
    else {
      const startLink: string = this.props.location.pathname + searchParams;
      sessionStorage.setItem(this.state.keyNameSessionLink, startLink);
    }


    // const ssoToken = cookie.load("token", true);
    // console.log("coookiieeee !!!!!!!!!!", ssoToken);
    // if(ssoToken == undefined || ssoToken == null)
    // {
    //   window.addEventListener("message",
    //   (e) => {
    //     console.log("test ++++++++++", e);
    //     if (e.origin === 'http://localhost:3000' && typeof e.data == 'object') {
    //       const dataIframe = e.data;

    //       if(dataIframe.type == "token")
    //       {
    //         cookie.remove("token");
    //         cookie.save('token', dataIframe.data, { path: '/' });
    //         console.log("ma data",dataIframe.data);
    //       }

    //     }
    //    })
    // }


    // Si on est pas authentifié on récupére le mode de connexion dans la base et on fait la bonne authentification
    if (Store.userConnected.id == undefined || Store.userConnected.id == null) {
      axios.get(Store.wsPath + '/1/paramYes/getParamYes').then(async responseParam => {
        Store.authMode = responseParam.data.authMode;
        if (responseParam.data.authMode == "Local") {

        }
        else if (responseParam.data.authMode == "AAD") {
          this.setState({ loadingAuth: true }, () => {
            this.connexionAAD().then(responseAAD => {
              // Load session start link
              const startLink: string = sessionStorage.getItem(this.state.keyNameSessionLink);

              if (startLink != undefined && startLink != null) {
                sessionStorage.removeItem(this.state.keyNameSessionLink);
                this.setState({ loadingAuth: false }, () => {
                  this.props.history.push(startLink);
                });
              }
              else {
                this.setState({ loadingAuth: false }, () => {
                  this.props.history.push("/");
                });
              }
            }).catch(error => {
              console.error(error);
              this.setState({ loadingAuth: false }, () => {
                this.props.history.push("/");
              });
            });
          });
        }
        else { // AD
          this.setState({ loadingAuth: true }, () => {
            this.newConnexionAD().then(responseAD => {
              // Load session start link
              const startLink: string = sessionStorage.getItem(this.state.keyNameSessionLink);

              if (startLink != undefined && startLink != null) {
                sessionStorage.removeItem(this.state.keyNameSessionLink);
                this.setState({ loadingAuth: false }, () => {
                  this.props.history.push(startLink);
                });
              }
              else {
                this.setState({ loadingAuth: false }, () => {
                  this.props.history.push("/");
                });
              }
            }).catch(error => {
              console.error(error);
              this.setState({ loadingAuth: false }, () => {
                this.props.history.push("/");
              });
            });
          });
        }
      }).catch(error => {
        Store.isLoginHidden = false;
        Store.snackBar.open = true
        Store.snackBar.error = true;
        Store.snackBar.message = "LYKA : Le serveur de connexion ne répond pas, veuillez contacter le support"
      });
    }
  }


  public getParameterByName(name) {
    const url = window.location.href;
    name = name.replace(/[\[\]]/g, '\\$&');
    const regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)')
    const results = regex.exec(url);
    if (!results) return undefined;
    if (!results[2]) return undefined;
    return decodeURIComponent(results[2].replace(/\+/g, ' '));
  }

  /* public getAllTaskByUser() { // si un utilisateur arrive sur LyFlow s’il a une tache en cours, on affiche le doc, sinon on redirige vers le tableau de bord
    axios.get(Store.wsPath + '/1/documents/getAllTaskByUserConnectedApp', {
      params : {
        userIdx : Store.userConnected.id,
      }
    }).then(response => )
  } */

  componentDidMount() {
    // storage = new CrossStorageClient('http://localhost:3000/1/authentication/login', {
    //   timeout: 5000,
    //   frameId: 'storageFrame'
    // });
  }

  public connexionAAD = () => {
    return new Promise<void>((resolve, reject) => {
      let ssoToken: any = "";
      if (cookie.load("token") == undefined && this.getParameterByName("ssoToken") == undefined) { // si aucun cookie et pas de param dans l'url redirection sur la connexion AAD
        window.location.assign(Store.wsPath + '/1/authentication/azureadoauth2');
      }
      else {
        if ((Store.userConnected == undefined || Store.userConnected.login == undefined) && cookie.load("token") != undefined) { // cookie existe mais store vide
          ssoToken = cookie.load("token", true);
        }
        else if ((Store.userConnected == undefined || Store.userConnected.login == undefined) && this.getParameterByName("ssoToken") != undefined) { // cookie n'existe pas, store vide mais param dans l'url présent
          ssoToken = this.getParameterByName("ssoToken");
        }

        if (ssoToken != "") { // ssoToken est rempli soit par le cookie soit par le param, on le vérifie
          axios.get(Store.wsPath + '/1/authentication/verifyToken', {
            headers: {
              Authorization: "Bearer l1Q7zkOL59cRqWBkQ12ZiGVW2DBL"
            }, params: { ssoToken: ssoToken }
          }).then((response) => {
            const data: any = jwt.decode(response.data.token); // user data
            cookie.remove("token");
            cookie.save('token', ssoToken, { path: '/' });
            this.setState({ loadingAuth: false });
            Store.userConnected = data.user;
            Store.isLoginHidden = false;
            window.history.replaceState({}, "Lyka", "/");

            resolve();
          }).catch(error => {
            cookie.remove("token");
            window.location.assign(Store.wsPath + '/1/authentication/azureadoauth2');

            reject("error Auth" + error);
            // // Test jj pour resoudre le problème de recharge à la premiere visite sur l'app (si pose problème commenter la ligne en dessous)
            // window.location.assign('/');
          });
        }
        else {
          // Contenu déplacé au dessus
          reject("Pas de sso token");
        }
      }

    });
  }

  public newConnexionAD = () => {
    return new Promise<void>((resolve, reject) => {
      let ssoToken: any = "";
      if (cookie.load("token") == undefined && this.getParameterByName("ssoToken") == undefined) { // si aucun cookie et pas de param dans l'url redirection sur la connexion AAD
        window.location.assign(Store.wsPath + '/1/authentication/login?origin='+window.location.origin);
      }
      else {
        if ((Store.userConnected == undefined || Store.userConnected.login == undefined) && cookie.load("token") != undefined) { // cookie existe mais store vide
          ssoToken = cookie.load("token", true);
        }
        else if ((Store.userConnected == undefined || Store.userConnected.login == undefined) && this.getParameterByName("ssoToken") != undefined) { // cookie n'existe pas, store vide mais param dans l'url présent
          ssoToken = this.getParameterByName("ssoToken");
        }

        if (ssoToken != "") { // ssoToken est rempli soit par le cookie soit par le param, on le vérifie
          axios.get(Store.wsPath + '/1/authentication/verifyToken', {
            headers: {
              Authorization: "Bearer l1Q7zkOL59cRqWBkQ12ZiGVW2DBL"
            }, params: { ssoToken: ssoToken }
          }).then((response) => {
            const data: any = jwt.decode(response.data.token); // user data
            cookie.remove("token");
            cookie.save('token', ssoToken, { path: '/' });
            this.setState({ loadingAuth: false });
            Store.userConnected = data.user;
            Store.isLoginHidden = false;
            window.history.replaceState({}, "Lyka", "/");

            resolve();
          }).catch(error => {
            cookie.remove("token");
            window.location.assign(Store.wsPath + '/1/authentication/login');
            reject("error Auth" + error);
          });
        }
        else {
          // Contenu déplacé au dessus
          reject("Pas de sso token");
        }
      }

    });
  }

  public connexionLocal = () => {
    if ((Store.userConnected == undefined || Store.userConnected.login == undefined) && cookie.load("token") != undefined) {
      const token = cookie.load("token", true);
      Store.isLoginHidden = false;
      axios.post(Store.wsPath + '/1/authentication/alreadyLogged', { token: token, redirectURL: "http://localhost:8080" }).then(response => {
        //  console.log("response.data", response.data)
        axios.get(Store.wsPath + '/1/authentication/verifyToken', {
          headers: {
            Authorization: "Bearer l1Q7zkOL59cRqWBkQ12ZiGVW2DBL"
          }, params: { ssoToken: response.data.ssoToken }
        }).then((res) => {
          //  console.log("response", res.data);
          const data: any = jwt.decode(res.data.token);
          // console.log("data", data)
          cookie.remove("token");
          cookie.save('token', res.data.token, { path: '/' });
          Store.userConnected = data.user;
        }).catch(error => console.error("error Auth", error))
      })
    }
    // else {
    //   // Store.isLoginHidden = false;
    //   if (this.getParameterByName("ssoToken") != undefined) {
    //     const token =this.getParameterByName("ssoToken")
    //     axios.get(Store.wsPath + '/1/authentication/verifyToken',{headers:{
    //         Authorization: "Bearer l1Q7zkOL59cRqWBkQ12ZiGVW2DBL"
    //     }, params:{ssoToken:token}}).then((response)=>{
    //         console.log("response",response.data);
    //         const data: any = jwt.decode(response.data.token);
    //         console.log("data",data)
    //         cookie.remove("token");
    //         cookie.save('token', response.data.token, { path: '/' });
    //         Store.userConnected = data.user;
    //     }).catch(error => console.log("error Auth",error))
    //   } else {
    //     // window.location.assign(Store.wsPath + '/1/authentication/login?urlSource=http://localhost:8080')
    //   }

    // }
    else {
      Store.isLoginHidden = false;
      if (cookie.load("token", true) != undefined) {
        const token = this.getParameterByName("ssoToken")
        axios.get(Store.wsPath + '/1/authentication/verifyToken', {
          headers: {
            Authorization: "Bearer l1Q7zkOL59cRqWBkQ12ZiGVW2DBL"
          }, params: { ssoToken: token }
        }).then((response) => {
          //  console.log("response", response.data);
          const data: any = jwt.decode(response.data.token);
          //  console.log("data", data)
          cookie.remove("token");
          cookie.save('token', response.data.token, { path: '/' });
          Store.userConnected = data.user;
        }).catch(error => console.error("error Auth", error));
      } else {
        // window.location.assign(Store.wsPath + '/1/authentication/login?urlSource=http://localhost:8080')
      }

    }
  }

  public connexionAD = () => {
    if ((Store.userConnected == undefined || Store.userConnected.login == undefined) && cookie.load("token") != undefined) {
      const token = cookie.load("token", true);
      const data: any = jwt.decode(token)
      axios.get(Store.wsPath + '/1/users/GetUserInfo', { params: { login: data._json.sAMAccountName } }).then((response) => {
        Store.userConnected = response.data;
        /* axios.get(Store.wsPath + '/1/param/getParam')
        .then(responseParam => {
          if (responseParam.data > "") {
              Store.GeneralConfig.authorization.connect = responseParam.data.connect
              Store.GeneralConfig.authorization.modif = responseParam.data.modif
              Store.userRiskMenu.usersRiskList= responseParam.data.userRisk;
              const tempArray :boolean = responseParam.data.userRisk.find(test => test == Store.userConnected.id)
              if (tempArray) {
                Store.userRiskMenu.userRisk = true
              } else {
                Store.userRiskMenu.userRisk = false
              }
              if (Store.userRiskMenu.usersRiskList.length >0) {
                Store.parameters.viewParameter = false
                Store.parameters.viewEI = true
                Store.parameters.viewPAQ = false
                Store.iconStatus = {
                  ei: true,
                  settings: false,
                  paq: false
              }
              } else {
                Store.parameters.viewParameter = true
                Store.parameters.viewEI = false
                Store.parameters.viewPAQ = false
                Store.iconStatus = {
                  ei: false,
                  settings: true,
                  paq: false
              }
              }
          }
          Store.isLoginHidden =false;
          cookie.remove("token");
          cookie.save('token', token, { path: '/' });
        }).catch(error =>{
          Store.snackBar.open = true
          Store.snackBar.error = true;
          Store.snackBar.message = "LYKA : L'identifiant n'existe pas, veuillez contacter le support"
  }) */
        Store.isLoginHidden = false;
        cookie.remove("token");
        cookie.save('token', token, { path: '/' });
      }).catch(error => {
        Store.snackBar.open = true
        Store.snackBar.error = true;
        Store.snackBar.message = "LYKA : L'identifiant n'existe pas, veuillez contacter le support"
      })
    }
    else {
      Store.isLoginHidden = false;
    }
  }

  public render(): JSX.Element {
    return (
      <MuiThemeProvider theme={theme}>
        {
          this.state.loadingAuth == true ?
            Store.funMode == true ?
              <div className={stylesheet.authLoadingContainer}>
                <img className={stylesheet.loadingLogo} alt="Chargement en cours..." src="./images/unicornhead.png" />
                Chargement en cours...
              </div>
              :
              <div className={stylesheet.authLoadingContainer}>
                <LoadingLogo text="Chargement en cours..." />
              </div>
            :
            Store.userConnected.id != undefined && Store.userConnected.id != null ?
              <Switch>
                <Layout exact path="/" menuContextuel={Store.contextualmenu} menuSharepoint={Store.menuSharepoint.menuSharepointOpen} component={Dashboard} />
                <ProtectedRoutes exact menuContextuel={Store.contextualmenu} menuSharepoint={Store.menuSharepoint.menuSharepointOpen} path="/document" component={Form} />
                <ProtectedRoutes menuContextuel={Store.contextualmenu} menuSharepoint={Store.menuSharepoint.menuSharepointOpen} path="/users" isAdmin={true} component={ParametersUser} />
                <ProtectedRoutes menuContextuel={Store.contextualmenu} menuSharepoint={Store.menuSharepoint.menuSharepointOpen} path="/models" isAdmin={true} component={Models} />
                <ProtectedRoutes exact menuContextuel={Store.contextualmenu} menuSharepoint={Store.menuSharepoint.menuSharepointOpen} path="/parameterStartAuto" isAdmin={true} component={parameterStartAuto} />
                <ProtectedRoutes exact menuContextuel={Store.contextualmenu} menuSharepoint={Store.menuSharepoint.menuSharepointOpen} path="/parameters" isAdmin={true} component={Parameter} />
                <ProtectedRoutes exact menuContextuel={Store.contextualmenu} menuSharepoint={Store.menuSharepoint.menuSharepointOpen} path="/mytasks" component={AllTasksUsers} />
                <ProtectedRoutes menuContextuel={Store.contextualmenu} menuSharepoint={Store.menuSharepoint.menuSharepointOpen} path="/search" component={Search} />
                <ProtectedRoutes menuContextuel={Store.contextualmenu} menuSharepoint={Store.menuSharepoint.menuSharepointOpen} path="/roles" isAdmin={true} component={Roles} />
                <ProtectedRoutes menuContextuel={Store.contextualmenu} menuSharepoint={Store.menuSharepoint.menuSharepointOpen} path="/mymessage" component={MyMessages} />
                <ProtectedRoutes menuContextuel={Store.contextualmenu} menuSharepoint={Store.menuSharepoint.menuSharepointOpen} path="/mydashboard" component={Dashboard} />
                <ProtectedRoutes menuContextuel={Store.contextualmenu} menuSharepoint={Store.menuSharepoint.menuSharepointOpen} path="/myaccount" component={Me} />
                <ProtectedRoutes menuContextuel={Store.contextualmenu} menuSharepoint={Store.menuSharepoint.menuSharepointOpen} path="/delegation" component={Delegation} />
                <ProtectedRoutes path="/HistoricActions/:idDoc" menuContextuel={Store.contextualmenu} menuSharepoint={Store.menuSharepoint.menuSharepointOpen} component={HistoricActions} />
                <ProtectedRoutes exact path="/refresh" menuContextuel={Store.contextualmenu} menuSharepoint={Store.menuSharepoint.menuSharepointOpen} component={refresh} />
                <ProtectedRoutes menuContextuel={Store.contextualmenu} menuSharepoint={Store.menuSharepoint.menuSharepointOpen} path="/groups" isAdmin={true} component={ListGroupes} />
                <ProtectedRoutes menuContextuel={Store.contextualmenu} menuSharepoint={Store.menuSharepoint.menuSharepointOpen} path="/forms" component={FormsList} />
                <ProtectedRoutes menuContextuel={Store.contextualmenu} menuSharepoint={Store.menuSharepoint.menuSharepointOpen} path="/createForm" component={CreateForm} />
                <ProtectedRoutes menuContextuel={Store.contextualmenu} menuSharepoint={Store.menuSharepoint.menuSharepointOpen} path="/fichedeposte" component={FicheDePoste} />
                <ProtectedRoutes menuContextuel={Store.contextualmenu} menuSharepoint={Store.menuSharepoint.menuSharepointOpen} path="/email" isAdmin={true} component={Email} />
                <ProtectedRoutes menuContextuel={Store.contextualmenu} menuSharepoint={Store.menuSharepoint.menuSharepointOpen} path="/emailContent" isAdmin={true} component={EmailContentEditor} />
                <Route path="*" render={() => (<div>Page non disponible</div>)} />
              </Switch>
              :
              Store.authMode == "AAD" || Store.authMode == "AD" ?
                Store.funMode == true ?
                  <div className={stylesheet.authLoadingContainer}>
                    <img className={stylesheet.loadingLogo} alt="Chargement en cours..." src="./images/unicornhead.png" />
                    Reconnexion en cours, veuillez patienter...
                  </div>
                  :
                  <div className={stylesheet.authLoadingContainer}>
                    <LoadingLogo text="Reconnexion en cours, veuillez patienter..." />
                  </div>
                :
                <HomePageUnconnected />
        }
        {/* <iframe id="iframeConnexion" src="http://localhost:3000/1/authentication/login" style={{display:"none"}}></iframe> */}
        <SnackBar/>
      </MuiThemeProvider>
    );
  }
}

export default withRouter(App);