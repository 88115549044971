import * as React from "react";
import { observer } from "mobx-react";
import { IEmailProps } from "./interfaces/IEmailProps";
import { IEmailState } from "./interfaces/IEmailState";
import styles from "./styles/Email.scss";
import Store from "../store/store";
import { RouteComponentProps, withRouter } from "react-router-dom";
import axios from "axios";
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import Icon from "@material-ui/core/Icon";
import Button from "@material-ui/core/Button";
import Spinner from "../Spinner/Spinner";

@observer
class Email extends React.Component<IEmailProps & RouteComponentProps, IEmailState> {
  constructor(props: IEmailProps & RouteComponentProps) {
    super(props);
    this.state = {
      listEmails: [],
      listEmailContents: [],
      loadingEmails: true
    };
  }

  public componentDidMount() {
    this.getAllEmail();
  }
  public componentDidUpdate(prevProps, prevState) {

  }
  titleHover = (type: string) => (cell: string, row: any) => {
    return (
      <span title={
        type == "userFrom" ? row
          : type == "userTo" ? row
            : ""
      }>{cell}</span>
    )
  }
  public getAllEmail = () => {
    try {
      axios.get(Store.wsPath + '/1/email/GetAllEmail').then((response) => {
        this.setState({listEmails:response.data,loadingEmails:false});
      })
    } catch (error) {
      console.error("Error on 'getAllEmail' : ", error)
    }
  }
  getActions = (cell, row) => {
    const url = "/emailContent?e=" + row.id;
    return (
      <div className={styles.actionTable}>
        <div className={styles.actionJaune} title="Editer le mail"><a href={url}><Icon className={styles.iconAction} >edit</Icon></a></div>
        {/* <div className={styles.actionJaune} title="Recevoir un exemple"><Icon className={styles.iconAction} onClick={() => { }}>mail</Icon></div> */}
      </div>
    )
  }

  getActionsCorpsMail = (cell, row) => {
    return (
      <div className={styles.actionTable}>
        <div className={styles.actionJaune} title="Créer ou modifier un brouillon"><Icon className={styles.iconAction} onClick={() => { }}>edit</Icon></div>
        <div className={styles.actionJaune} title="Valider le brouillon"><Icon className={styles.iconAction} onClick={() => { }}>edit</Icon></div>
        <div className={styles.actionRouge} title="Supprimer"><Icon className={styles.iconAction} onClick={() => { }}>delete</Icon></div>
      </div>
    )
  }
  render() {
    const optionsEmailList = {
      sortIndicator: true,
      defaultSortName: 'id',
      noDataText: 'Aucun mail à afficher',
      sizePerPage: 10
    };
    return (
      <>
        {/* <div className={styles.block}>
          <div className={styles.contentCheckbox} style={{ background: Store.themeParam.backgroundPrimary }}>
            <h5>Corps des mails</h5>
          </div>
          {/* <div className={styles.actionBar}>
                <Button onClick={this.onNewModel} className={styles.addButton} variant="contained" color="primary"><Icon>add</Icon>Créer un modèle</Button>
            </div> */}
          {/*<div className={styles.tableDeleg}>
            {this.state.loadingEmails ?
              <Spinner label="Chargement des corps de mail" labelPlacement="bottom" style={{ margin: "30px" }} />
              :
              <BootstrapTable data={this.state.listEmailContents} options={optionsEmailList}
                pagination
                trClassName={styles.trBootstrapTableModel}
                search
                searchPlaceholder="Rechercher..."
                bordered={false}
                striped
                tableHeaderClass={styles.tableThHeader}
                headerContainerClass={styles.tableHeader}
              >
                <TableHeaderColumn dataField='id' hidden isKey>id</TableHeaderColumn>
                <TableHeaderColumn dataField='name' tdStyle={{ verticalAlign: 'middle' }}>Nom</TableHeaderColumn>
                <TableHeaderColumn dataField='circuitDisplay' tdStyle={{ verticalAlign: 'middle' }}>Dernière modification</TableHeaderColumn>
                <TableHeaderColumn dataField='userFromDisplay' tdStyle={{ verticalAlign: 'middle' }} dataFormat={this.titleHover("userFrom")}
                  searchable={(Store.userConnected.typeUserLydoc === "ged" || Store.userConnected.typeUserLydoc === "admin")}
                >
                  Modifié Par
                        </TableHeaderColumn>
                <TableHeaderColumn width="140px" dataField="action" tdStyle={{ padding: '0px' }} dataFormat={this.getActionsCorpsMail}>Actions</TableHeaderColumn>
              </BootstrapTable>
            }
          </div>
        </div> */}
        <div className={styles.block}>
          <div className={styles.contentCheckbox} style={{ background: Store.themeParam.backgroundPrimary }}>
            <h5>Configuration des mails </h5>
          </div>
          {/* <div className={styles.actionBar}>
                        <Button onClick={this.onNewModel} className={styles.addButton} variant="contained" color="primary"><Icon>add</Icon>Créer un modèle</Button>
                    </div> */}
          <div className={styles.tableDeleg}>
            {this.state.loadingEmails === true ?
              <Spinner label="Chargement des mails" labelPlacement="bottom" style={{ margin: "30px" }} />
              :
              <BootstrapTable data={this.state.listEmails} options={optionsEmailList}
                pagination
                trClassName={styles.trBootstrapTableModel}
                search
                searchPlaceholder="Rechercher..."
                bordered={false}
                striped
                tableHeaderClass={styles.tableThHeader}
                headerContainerClass={styles.tableHeader}
              >
                <TableHeaderColumn dataField='id' hidden isKey>id</TableHeaderColumn>
                <TableHeaderColumn width="200px" dataField='name' tdStyle={{ verticalAlign: 'middle' }}>Nom</TableHeaderColumn>
                <TableHeaderColumn dataField='description' tdStyle={{ verticalAlign: 'middle' }}>Description</TableHeaderColumn>
                <TableHeaderColumn width="140px" dataField="action" tdStyle={{ padding: '0px'}} dataFormat={this.getActions}>Actions</TableHeaderColumn>
              </BootstrapTable>
            }
          </div>
        </div>
      </>
    );
  }
}

export default withRouter(Email);