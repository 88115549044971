import * as React from "react";
import { observer } from "mobx-react";
import { IEmailContentEditorProps } from "./interfaces/IEmailContentEditorProps";
import { IEmailContentEditorState } from "./interfaces/IEmailContentEditorState";
import { useQuery } from "./../helper/helpers";
import styles from "./styles/EmailContentEditor.scss";
import Store from "../store/store";
import CKEditor from "ckeditor4-react";
import { RouteComponentProps, withRouter } from "react-router-dom";
import axios from "axios";
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import Icon from "@material-ui/core/Icon";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Button from "@material-ui/core/Button";
import Spinner from "../Spinner/Spinner";
CKEditor.editorUrl = "https://cdn.ckeditor.com/4.5.4/full-all/ckeditor.js";

@observer
class EmailContentEditor extends React.Component<IEmailContentEditorProps & RouteComponentProps, IEmailContentEditorState> {
  constructor(props: IEmailContentEditorProps & RouteComponentProps) {
    super(props);

    this.state = {
      emailData: undefined,
      tokens: [],
      loadingEmail: true,
      editor: undefined,
      data: "",
      modalSave: false,
      modalCancel: false
    };


  }

  public componentDidMount() {
    const query = useQuery()
    const idEmail: number = parseInt(query.get("e"));
    this.getEmailData(idEmail);
  }
  public componentDidUpdate(prevProps, prevState) {

  }
  public saveData = async () => {
    const data = this.state.editor.editor.getData();
    axios.post(Store.wsPath + "/1/email/UpdateEmailById", {
      id: this.state.emailData.EmailContent.id,
      data: data
    }).then(response => {
      this.props.history.push("/email");

    }).catch(error => {
      Store.snackBar = {
        open: true,
        error: true,
        message: "Une erreur est suvenue lors de l'enregistrement des modifications."
      }
      console.error('ERROR enregistrement modif mail: ', error)
    });
  }

  public cancel = async () => {
    this.props.history.push("/email");
  }
  public onCloseModalSave = () => {
    this.setState({
      modalSave: false
    });
  }
  public onCloseModalCancel = () => {
    this.setState({
      modalCancel: false
    });
  }
  public openModalSave = () => {
    this.setState({
      modalSave: true
    });
  }
  public openModalCancel = () => {
    this.setState({
      modalCancel: true
    });
  }
  public getEmailData = (idEmail) => {
    axios.get(Store.wsPath + '/1/email/GetEmailById', { params: { id: idEmail } }).then((response) => {
      const emailData = response.data;
      const tokens = [];
      if (response.data.tokens != null && response.data.tokens != undefined) {
        response.data.tokens.forEach(token => {
          tokens.push([token.title, token.token])
        });
      }
      this.setState({ emailData: emailData, tokens: tokens, loadingEmail: false })
    })


  }
  render() {

    return (
      <div className={styles.block}>
        <div className={styles.contentCheckbox} style={{ background: Store.themeParam.backgroundPrimary }}>
          <h5>Corps des mails</h5>
        </div>

        <div className={styles.tableDeleg}>
          {this.state.loadingEmail === true ?
            <Spinner label="Chargement des mails" labelPlacement="bottom" style={{ margin: "30px" }} />
            : <CKEditor
              data={this.state.emailData.EmailContent.contentEmail.text}
              onBeforeLoad={CKEDITOR => {

                CKEDITOR.plugins.addExternal(
                  'token', '/token/', 'plugin.js'
                );

              }}
              onInstanceReady={(editor) => {
                this.setState({ editor: editor })
              }}
              config={{
                toolbar: [
                  { name: "tools", items: ["Maximize", "CreateToken"] },
                  {
                    name: "clipboard",
                    items: ["Cut", "Copy", "Paste", "PasteText", "-", "Undo", "Redo"]
                  },
                  { name: "links", items: ["Link", "Unlink"] },
                  { name: "document", items: ["Source"] },
                  {
                    name: "basicstyles",
                    items: [
                      "Bold",
                      "Italic",
                      "Underline",
                      "Strike",
                      "-",
                      "Subscript",
                      "Superscript"
                    ]
                  },
                  {
                    name: "paragraph",
                    items: [
                      "NumberedList",
                      "BulletedList",
                      "-",
                      "Outdent",
                      "Indent",
                      "Blockquote"
                    ]
                  },
                  {
                    name: "align",
                    items: [
                      "AlignLeft",
                      "JustifyLeft",
                      "JustifyCenter",
                      "JustifyRight",
                      "JustifyBlock"
                    ]
                  },
                  { name: "styles", items: ["Format", "-", "Font", "-", "FontSize"] },
                  { name: "colors", items: ["TextColor", "BGColor"] },
                  { name: "insert", items: ["Image", "Table", "HorizontalRule"] },
                  "/"
                ],
                defaultLanguage: "fr",
                extraPlugins: "colorbutton,colordialog,font,token",
                availableTokens: this.state.tokens,
                tokenStart: "$(",
                tokenEnd: ")",
                removeButtons: ""
              }}
            />
          }

        </div>
        {this.state.loadingEmail === true ? "" :
          <div>
            <Button
              onClick={this.openModalSave}
              variant="contained"
              color="primary"
              style={{ marginBottom: "20px",marginRight:"20px", width: "300px" }}
            >
              Enregistrer les modifications
            </Button>
            <Button
              onClick={this.openModalCancel}
              variant="contained"
              color="secondary"
              style={{ marginBottom: "20px", width: "200px" }}
            >
              Annuler
            </Button>
          </div>
        }
        
        <Dialog
          maxWidth='xs'
          open={this.state.modalSave}
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
        >
          <DialogTitle>
            <div className={styles.modalTitle}>Sauvegarder les modifications</div>
          </DialogTitle>
          <DialogContent>
            <div className={styles.modalContent}>
              <p>Vous allez sauvegarder vos modifications, êtes vous sûr(e) de cette action ?</p>
              <div className={styles.modalButtons}>
                <Button
                  onClick={this.saveData}
                  variant="contained"
                  color="primary"
                >
                  Sauvegarder
                </Button>
                <Button
                  onClick={this.onCloseModalSave}
                  variant="contained"
                  color="secondary"
                >
                  Annuler
                </Button>
              </div>
            </div>
          </DialogContent>
        </Dialog>
        <Dialog
          maxWidth='xs'
          open={this.state.modalCancel}
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
        >
          <DialogTitle>
            <div className={styles.modalTitle}>Annuler les modifications</div>
          </DialogTitle>
          <DialogContent>
            <div className={styles.modalContent}>
              <p>Vous allez annuler vos modifications, êtes vous sûr(e) de cette action ?</p>
              <div className={styles.modalButtons}>
                <Button
                  onClick={this.cancel}
                  variant="contained"
                  color="primary"
                >
                  Valider
                </Button>
                <Button
                  onClick={this.onCloseModalCancel}
                  variant="contained"
                  color="secondary"
                >
                  Retourner à vos modifications
                </Button>
              </div>
            </div>
          </DialogContent>
        </Dialog>
      </div>
    );
  }
}

export default withRouter(EmailContentEditor);