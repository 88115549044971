import * as React from 'react';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Dialog from '@material-ui/core/Dialog';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormLabel from '@material-ui/core/FormLabel';
import Checkbox from "@material-ui/core/Checkbox";
import Store from '../store/store';
import axios from 'axios';
import { observer } from "mobx-react";
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table'
import SelectReact, { createFilter } from 'react-select';
import Banettes from "../banette/banette"
import { ISearch, ISuggestionList } from './interfaces/ISearchWf';
import { RouteComponentProps, withRouter } from 'react-router-dom';
const modal = require("../../styles/styles.module.scss");
const styles = require('./styles/styles.scss');
import Icon from "@material-ui/core/Icon";
import { IWSReturnResultSearchFilter, IAllBannetteAndContentWS,IWSReturnResultSearchFilterRow } from '../Workflow/interfaces/IWorkflow';
import { MenuList, customMultipleValue } from './../helper/reactSelectCustomRender';
import PopoverGroup from "../popover/PopoverGroup";
import DialogTitle from '../../../node_modules/@material-ui/core/DialogTitle';
import Spinner from '../Spinner/Spinner';
import { DialogFooter, DialogType, Dialog as DialogFabric } from "office-ui-fabric-react/lib/Dialog";

import { Spinner as SpinnerFabric, SpinnerSize } from "office-ui-fabric-react/lib/Spinner";
import { PrimaryButton, DefaultButton } from "office-ui-fabric-react/lib/Button";
import Delete from '@material-ui/icons/Delete';

// export interface SearchProps {
// }

export interface SearchState {
  search: ISearch;
  listDisplaying?: IWSReturnResultSearchFilterRow[];
   openModal?: boolean;
  allModelsOptions?: any[];
  allFormsOptions?:any[];
  suggestionTask?: any[];
  openNameSearch?: boolean;
  displayMoreCriters?: boolean;
  choiceProject?: number[];
  openModalDeletedBanette?: boolean;
  choiceProjectStr?: string[];
  choiceCircuit?: number[];
  choiceForms?: number[];
  ongletSelect?: number;
  choiceTask?: number[];
  isDisplayingProject?: boolean;
  valueChoiceProjectTask?: any;
  choiceUser?: number[];
  allBannetteAndContent: IAllBannetteAndContentWS[];
  isNewSearch?: boolean;
  isPublic?: boolean;
  includeArchived?:boolean;
  idUser?: number;
  idCurrentBannette?: number;
  idBanette?: number;
  loadData: boolean;
  loadSaveBanette: boolean;
  loadDeleteBanette: boolean;
  openDeleteBanette: boolean;
  loadTable: boolean;
  allReadyRefresh:boolean;
  textRefresh:string;
  deleteDialogWFParams:{
    hideDialog: boolean,
    loading: boolean,
    error: boolean,
  },
  selectedWFToDelete:number;
  totalRows:number;
}

export interface IDateChoice {
  name: string,
  value: number,
  label: string,
  active: boolean
}


const tabStateProject = [{ value: 1, label: 'En cours' }, { value: 2, label: "Retourné" }, { value: 3, label: "Terminé" }, { value: 4, label: "Refusé" }, { value: 5, label: "En risque de retard" }, { value: 6, label: "En retard" },{value:7,label:"En attente de traitement"}]

const tabStateTask = [{ value: 1, label: "En préparation" }, { value: 2, label: "En cours" }, { value: 3, label: "Terminée" }]
@observer
class Search extends React.Component<RouteComponentProps<any>, SearchState>{
  public constructor(props: any) { // Dans le constructeur, on initialise toujours les states
    super(props)
    this.state = {
      search: {
        userConnected: false,
        idWf: [],
        idForm:[],
        choiceUser: [],
        statusProjet:[],
        statusTask:[]
      },
      deleteDialogWFParams: {
        hideDialog: true,
        loading: false,
        error: false,
      },
      selectedWFToDelete:null,
      allBannetteAndContent: [],
      listDisplaying: [],
      suggestionTask: tabStateTask,
      openModal: false,
      valueChoiceProjectTask: "project",
      isDisplayingProject: false,
      displayMoreCriters: false,
      allModelsOptions: [],
      allFormsOptions:[],
      ongletSelect: 0,
      choiceProject: [],
      choiceTask: [],
      choiceCircuit: [],
      choiceForms:[],
      choiceProjectStr: [],
      choiceUser: [],
      openNameSearch: false,
      isNewSearch: false,
      idUser: Store.userConnected.id,
      isPublic: false,
      includeArchived:false,
      idCurrentBannette: 1,
      loadData: false,
      loadSaveBanette: false,
      loadDeleteBanette: false,
      openDeleteBanette: false,
      loadTable: true,
      allReadyRefresh:false,
      textRefresh:"Mettre à jour les banettes",
      totalRows:0
    }

    const firstLoading: boolean = true
    this.getAllBanette("first");
    //this.displayTabFiltered(firstLoading)
    // this.getAllSaveSearch()
    this.getAllWorkflows()
  }

  public getAllBanette = (mode?: string, idSearch?: number) => {
    try {
      axios.post(Store.wsPath + '/1/workflows/getBannettesAndContent', {
        id: Store.userConnected.id,
        displayBanette: false
      }).then((response) => {
        const listDisplay: IWSReturnResultSearchFilterRow[] = [];
        if (mode == "first") {
          response.data[0].projects.map((project, indexP) => {
            if ( project.WfSteps.length == 0) {
              listDisplay.push({
                uniqueIdString: indexP.toString() + "0" + "0",
                idWf: project.id,
                nameWf: project.name,
                stateWf: project.state,
                doc: project.Document.title,
                stepId: 0,
                acteurTask: undefined,
                idTask: 0,
                nameTask: "",
                stateTask: "",
                stateStep: "",
                emailActeur: "",
                editedGroup: []
              })
            } else {
              project.WfSteps.map((step, indexS) => {
                if (step.WfTasks.length == 0) {
                  listDisplay.push({
                    uniqueIdString: indexP.toString() + indexS.toString() + "0",
                    idWf: project.id,
                    nameWf: project.name,
                    stateWf: project.state,
                    doc: project.Document.title,
                    stepId: step.id,
                    acteurTask: undefined,
                    idTask: 0,
                    nameTask: "",
                    stateTask: "",
                    stateStep: step.state,
                    emailActeur: "",
                    editedGroup: []
                  })
                } else {
                  step.WfTasks.map((task, indexT) => {
                    listDisplay.push({
                      uniqueIdString: indexP.toString() + indexS.toString() + indexT.toString(),
                      idWf: project.id,
                      nameWf: project.name,
                      stateWf: project.state,
                      doc: project.Document.title,
                      stepId: step.id,
                      acteurTask: task.User,
                      idTask: task.id,
                      nameTask: task.name,
                      stateTask: task.state,
                      stateStep: step.state,
                      emailActeur: task.User.email,
                      editedGroup: task.editedGroup
                    })
                  })
                }
              }) 
            }
            // project.WfSteps.map((step, indexS) => {
            //   step.WfTasks.map((task, indexT) => {
            //     listDisplay.push({
            //       uniqueIdString: indexP.toString() + indexS.toString() + indexT.toString(),
            //       idWf: project.id,
            //       nameWf: project.name,
            //       stateWf: project.state,
            //       doc: project.Document.title,
            //       stepId: step.id,
            //       acteurTask: task.User,
            //       idTask: task.id,
            //       nameTask: task.name,
            //       stateTask: task.state,
            //       stateStep: step.state,
            //       emailActeur: task.User.email,
            //       editedGroup: task.editedGroup
            //     })
            //   })
            // })
          })
          this.setState({
            allBannetteAndContent: response.data,
            isDisplayingProject: response.data[0].type == undefined || response.data[0].type == "project" ? true : false,
            listDisplaying: listDisplay,
            loadData: false,
            openModal: false,
            loadTable: false
          })
        } else if (mode == "update") {
          const updatedSearch = response.data.find(rd => rd.id == idSearch)
          if (updatedSearch.type != "task") {
            updatedSearch.projects.map((project, indexP) => {
              if ( project.WfSteps.length == 0) {
                listDisplay.push({
                  uniqueIdString: indexP.toString() + "0" + "0",
                  idWf: project.id,
                  nameWf: project.name,
                  stateWf: project.state,
                  doc: project.Document.title,
                  stepId: 0,
                  acteurTask: undefined,
                  idTask: 0,
                  nameTask: "",
                  stateTask: "",
                  stateStep: "",
                  emailActeur: "",
                  editedGroup: []
                })
              } else {
                project.WfSteps.map((step, indexS) => {
                  if (step.WfTasks.length == 0) {
                    listDisplay.push({
                      uniqueIdString: indexP.toString() + indexS.toString() + "0",
                      idWf: project.id,
                      nameWf: project.name,
                      stateWf: project.state,
                      doc: project.Document.title,
                      stepId: step.id,
                      acteurTask: undefined,
                      idTask: 0,
                      nameTask: "",
                      stateTask: "",
                      stateStep: step.state,
                      emailActeur: "",
                      editedGroup: []
                    })
                  } else {
                    step.WfTasks.map((task, indexT) => {
                      listDisplay.push({
                        uniqueIdString: indexP.toString() + indexS.toString() + indexT.toString(),
                        idWf: project.id,
                        nameWf: project.name,
                        stateWf: project.state,
                        doc: project.Document.title,
                        stepId: step.id,
                        acteurTask: task.User,
                        idTask: task.id,
                        nameTask: task.name,
                        stateTask: task.state,
                        stateStep: step.state,
                        emailActeur: task.User.email,
                        editedGroup: task.editedGroup
                      })
                    })
                  }
                }) 
              }
              // project.WfSteps.map((step, indexS) => {
              //   step.WfTasks.map((task, indexT) => {
              //     listDisplay.push({
              //       uniqueIdString: indexP.toString() + indexS.toString() + indexT.toString(),
              //       idWf: project.id,
              //       nameWf: project.name,
              //       stateWf: project.state,
              //       doc: project.Document.title,
              //       stepId: step.id,
              //       acteurTask: task.User,
              //       idTask: task.id,
              //       nameTask: task.name,
              //       stateTask: task.state,
              //       stateStep: step.state,
              //       emailActeur: task.User.email,
              //       editedGroup: task.editedGroup
              //     })
              //   })
              // })
            })
          } else {
            updatedSearch.tasks.forEach(tf => {
              const selectedProject = updatedSearch.projects.find(pf => pf.id == tf.idwf);
              const selectedStep = selectedProject.WfSteps.find(sf => sf.id == tf.idStep);
              const selectedTask = selectedStep.WfTasks.find(st => st.id == tf.idTask);
              listDisplay.push({
                idWf: selectedProject.id,
                nameWf: selectedProject.name,
                stateWf: selectedProject.state,
                nameTask: selectedTask.name,
                stateTask: selectedTask.state,
                doc: selectedProject.Document.title,
                idTask: selectedTask.id,
                stepId: selectedStep.id,
                stateStep: selectedStep.state,
                acteurTask: selectedTask.User,
                emailActeur: selectedTask.User.email,
                editedGroup: selectedTask.editedGroup
              });
            })
          }
          this.setState({
            choiceProject: [],
            choiceTask: [],
            choiceCircuit: [],
            choiceForms:[],
            choiceProjectStr: [],
            choiceUser: [],
            allBannetteAndContent: response.data,
            isDisplayingProject: updatedSearch.type == undefined || updatedSearch.type == "project" ? true : false,
            listDisplaying: listDisplay,
            loadData: false,
            openModal: false
          })
        }
        else {

          this.setState({
            choiceProject: [],
            choiceTask: [],
            choiceCircuit: [],
            choiceForms:[],
            choiceProjectStr: [],
            choiceUser: [],
            allBannetteAndContent: response.data,
            loadData: false,
            openModal: false,
            openNameSearch: false,
            loadSaveBanette: false
          })
        }
      })
    } catch (error) {
      console.error("Error on 'getAllBanetteAndContent' : ", error)
    }
  }
  public onChange = (name: string) => event => {
   if (name == "pleinText") {
      const tempSearch = this.state.search
      tempSearch.pleinText = event.target.value
      this.setState({
        search: tempSearch
      })
    } else if (name == "nameSearch") {
      const tempSearch = this.state.search
      tempSearch.nameSearch = event.target.value
      this.setState({
        search: tempSearch
      })
    }

  }
  public updateReactSelectRole = (idChoice: number, name: string) => {

  }
  public updateBanette =(allReadyRefresh:boolean)=>{
    if(allReadyRefresh == false){
      this.setState({textRefresh:"Banettes mises à jour",allReadyRefresh:true});
      ////////////////////////// Lancement du démarrage automatique ///////////////////////////////
      axios.get(Store.wsPath + '/1/jobs/autoStartWF').then(response => {
        console.log("Démarrage Auto : ", response);
      });
      ////////////////////////////////////////////////////////////////////////////////////////////
    }
  }

  public onClickDisplayTabFiltered = (offset)=>() => {
    this.setState({ loadData: true })
    const idUser = Store.userConnected.id;
    axios.post(Store.wsPath + '/1/workflows/returnResultSearchFilter', {
      search: this.state.search,
      idUser: idUser,
      objectChoice: [],
      choiceTaskproject: this.state.valueChoiceProjectTask,
      includeArchived:this.state.includeArchived,
      offset:offset
    }).then((rep) => {
      console.log("rep.data",rep.data)
      const result: IWSReturnResultSearchFilterRow[] = rep.data.rows;
      result.forEach((element, index) => {
        const idWf = element.idWf.toString();
        const idStep = element.stepId != undefined ? element.stepId.toString() : index.toString();
        const idTask = element.idTask != undefined ? element.idTask.toString() : index.toString();

        element.uniqueIdString = idWf + idStep + idTask + index.toString();
      });
      let resultRow =[];
      if (offset == 0) {
        resultRow = result
      }else{
        resultRow = [...this.state.listDisplaying,...result]
      }
      
      this.setState({
        isDisplayingProject: this.state.valueChoiceProjectTask == "project" ? true : false,
        listDisplaying: resultRow,
        totalRows:offset == 0?rep.data.count:this.state.totalRows,
        openModal: false,
        isNewSearch: true,
        loadData: false
      })
    })
  }

  public updateSearchSave = () => {
    this.setState({ loadData: true })
    axios.post(Store.wsPath + '/1/workflows/updateSearchSave', {
      id: this.state.search.id,
      search: this.state.search,
      choiceProject: this.state.choiceProject,
      choiceProjectStr: this.state.choiceProjectStr,
      choiceTask: this.state.choiceTask,
      choiceCircuit: this.state.choiceCircuit,
      choiceForms: this.state.choiceForms,
      choiceUser: this.state.choiceUser,
      type: this.state.valueChoiceProjectTask != "" ? this.state.valueChoiceProjectTask : "project",
      idUser: this.state.idUser,
      isPublic: this.state.isPublic
    }).then((response) => {
      this.getAllBanette("update", this.state.search.id);
    })
  }

  public getAllWorkflows = () => {
    axios.get(Store.wsPath + '/1/workflows/getAllModelWf').then((response) => {
      const tabTempForm: any[] = []
      response.data.map((wf) => {
        tabTempForm.push({ value: wf.id, label: wf.name })
      })
      if(Store.searchForm == true){
        axios.get(Store.wsPath + '/1/formulaire/GetReallyAllForms').then((res) => {
          const tempForm:any[]= [];
          res.data.map((f)=>{
            tempForm.push({value:f.id,label:f.name})
          })
        this.setState({
            allModelsOptions: tabTempForm,
            allFormsOptions :tempForm
          })
        })
      }else{
        this.setState({
          allModelsOptions: tabTempForm
        })
      }
      

    })
  }
  public deleteWF = (idWf: number) => (event: any) => {
    this.setState({ deleteDialogWFParams: { ...this.state.deleteDialogWFParams, loading: true } }, async () => {
      try {
        const resultDelete = await axios.post(Store.wsPath + "/1/workflows/deleteWf", { idWF: idWf });
        // const allFormList: IFormInfo[] = [...this.state.allForms].filter(frm => frm.id != form.id);
        this.state.listDisplaying
        const filteredResult:any[] =[...this.state.listDisplaying.filter(la => la.idWf != idWf)]
        Store.snackBar = {
          message: "Circuit supprimé avec succès",
          error: false,
          open: true
        };

        this.setState({ deleteDialogWFParams: { ...this.state.deleteDialogWFParams, hideDialog: true }, selectedWFToDelete: null, listDisplaying:filteredResult });
        
      } catch (error) {
        console.error("Error 'deleteWF' : ", error);
        this.setState({ deleteDialogWFParams: { ...this.state.deleteDialogWFParams, loading: false, error: true } });
        Store.snackBar = {
          message: "Une erreur est survenue lors de la suppression de la demande",
          error: true,
          open: true
        };
      }
    });
  }
  public openCloseDeleteWFModal = (open: boolean, idWf?: number) => event => {
    if (open == true) {
      this.setState({ deleteDialogWFParams: { hideDialog: false, loading: false, error: false }, selectedWFToDelete: idWf });
    }
    else {
      this.setState({ deleteDialogWFParams: { hideDialog: true, loading: false, error: false } });
    }
  }
  public getAllTasksCb = (search: IAllBannetteAndContentWS) => {
    const listDisplay: IWSReturnResultSearchFilterRow[] = [];
    if (search.type != "task") {
      search.projects.map((project, indexP) => {
        if ( project.WfSteps.length == 0) {
          listDisplay.push({
            uniqueIdString: indexP.toString() + "0" + "0",
            idWf: project.id,
            nameWf: project.name,
            stateWf: project.state,
            doc: project.Document.title,
            stepId: 0,
            acteurTask: undefined,
            idTask: 0,
            nameTask: "",
            stateTask: "",
            stateStep: "",
            emailActeur: "",
            editedGroup: []
          })
        } else {
          project.WfSteps.map((step, indexS) => {
            if (step.WfTasks.length == 0) {
              listDisplay.push({
                uniqueIdString: indexP.toString() + indexS.toString() + "0",
                idWf: project.id,
                nameWf: project.name,
                stateWf: project.state,
                doc: project.Document.title,
                stepId: step.id,
                acteurTask: undefined,
                idTask: 0,
                nameTask: "",
                stateTask: "",
                stateStep: step.state,
                emailActeur: "",
                editedGroup: []
              })
            } else {
              step.WfTasks.map((task, indexT) => {
                listDisplay.push({
                  uniqueIdString: indexP.toString() + indexS.toString() + indexT.toString(),
                  idWf: project.id,
                  nameWf: project.name,
                  stateWf: project.state,
                  doc: project.Document.title,
                  stepId: step.id,
                  acteurTask: task.User,
                  idTask: task.id,
                  nameTask: task.name,
                  stateTask: task.state,
                  stateStep: step.state,
                  emailActeur: task.User.email,
                  editedGroup: task.editedGroup
                })
              })
            }
          }) 
        }
      })
    }
    else {
      search.tasks.forEach(tf => {
        const selectedProject = search.projects.find(pf => pf.id == tf.idwf);
        const selectedStep = selectedProject.WfSteps.find(sf => sf.id == tf.idStep);
        const selectedTask = selectedStep.WfTasks.find(st => st.id == tf.idTask)
        listDisplay.push({ 
          idWf: selectedProject.id, 
          nameWf: selectedProject.name, 
          stateWf: selectedProject.state, 
          nameTask: selectedTask.name, 
          stateTask: selectedTask.state, 
          doc: selectedProject.Document.title, 
          idTask: selectedTask.id, 
          stepId: selectedStep.id, 
          stateStep: selectedStep.state, 
          acteurTask: selectedTask.User, 
          emailActeur: selectedTask.User.email,
          editedGroup: selectedTask.editedGroup
        });
      })
    }
    this.setState({
      isDisplayingProject: search.type == undefined || search.type == "project" ? true : false,
      listDisplaying: listDisplay,
      idCurrentBannette: search.id
    })
  }

  public updateReactSelect = (search: any[], element: string) => {
    if (element == "projet") {
      const tempArrayProject: any[] = [];
      if (search) {
        search.forEach(idOption => {
          const tempSuggestion = tabStateProject.find(suggestion => suggestion.value == idOption);
          if (tempSuggestion != undefined) {
            tempArrayProject.push(tempSuggestion);
          }

        });
      }
      return tempArrayProject
    }

    if (element == "task") {
      const tempArrayTask: any[] = [];
      if (search) {
        search.forEach(elmt => {
          const tempSuggestion = this.state.suggestionTask.find(suggestion => suggestion.value == elmt);
          if (tempSuggestion != undefined) {
            tempArrayTask.push(tempSuggestion);
          }
        });
      }
      return tempArrayTask
    }

    if (element == "taskuser") {
      const tempArrayUser: any[] = []
      if (search) {
        search.forEach(elmt => {
          const tempSuggestion = Store.suggestionsUser.find(suggestion => suggestion.value == elmt);
          if (tempSuggestion != undefined) {
            tempArrayUser.push(tempSuggestion);
          }
        });
      }
      return tempArrayUser
    }

    if (element == "choiceCircuit") {
      const tempArrayWorkflow: any[] = []
      if (search) {
        const allModelOptions =[...this.state.allModelsOptions];
        search.forEach(elmt => {
          const tempSuggestion = allModelOptions.find(suggestion => suggestion.value == elmt);
          if (tempSuggestion != undefined) {
            tempArrayWorkflow.push(tempSuggestion);
          }
        });
      }
      return tempArrayWorkflow
    }

  }
  public onChangeSelect = (option, type) => {
    const tempChoice: any = [] = []
    if (type == "prj") {
      const tempState = this.state.search
      if (option != null && option.length > 0) {
        const tempArrayIdOption = option.map(role => role.value);
        const tempArrayLabelOption = option.map(role => role.label);
        // const tempState = this.state.search
        const statusProjet = [];
        tempArrayLabelOption.forEach((label: string) => {
          statusProjet.push(label);
        });
        tempState.statusProjet = statusProjet
        // const tempState = this.state.idsChoice;
        // tempState.idsRole = tempArray;
        this.setState({ search: tempState, choiceProject: tempArrayIdOption, choiceProjectStr: tempArrayLabelOption });
      } else {
        tempState.statusProjet =[];
        this.setState({ choiceProject: [], choiceProjectStr: [], search: tempState });
      }
    }
    if(type == "typeDisplay"){
      this.setState({valueChoiceProjectTask:option.value}) 
    }
    if (type == "task") {
      const tempState = this.state.search
      if (option.length > 0) {
        const tempArray = option.map(role => role.value);
        
        const statusTask = []
        for (let i = 0; i < option.length; i++) {
          statusTask.push(option[i].label);
        }
        tempState.statusTask= statusTask
        this.setState({ choiceTask: tempArray, search: tempState });
      } else {
        this.setState({
          choiceTask: [],search:tempState
        })
      }
    }

    if (type == "user") {
      const tempArray = option.map(role => role.value)
      const tempSearch = this.state.search
      tempSearch.choiceUser = tempArray
      this.setState({
        search: tempSearch,
        choiceUser: tempArray
      })
    }
    if (type == "allWf") {
      const tempSearch = this.state.search
      if (option != null && option.length > 0) {
        
        tempSearch.idWf = option
        const tempchoiceCircuit = []
        option.forEach(opt => {
          tempchoiceCircuit.push(opt.value)
        })
        this.setState({
          choiceCircuit: tempchoiceCircuit,
          search: tempSearch
        })
      }else{
        this.setState({
          choiceCircuit: [],search:tempSearch
        })
      }
    }
    if (type == "allForms") {
      if (option != null && option.length > 0) {
        const tempSearch = this.state.search
        tempSearch.idForm = option
        const tempchoiceForms = []
        option.forEach(opt => {
          tempchoiceForms.push(opt.value)
        })
        this.setState({
          choiceForms: tempchoiceForms,
          search: tempSearch
        })
      }
    }
  }
  public modalClose = () => {
    // Store.openModal = false
    this.setState({ openModal: false })
  }

 
  public openNameSearch = () => {
    this.setState({
      openNameSearch: true
    })
  }
  public onSaveSearch = () => {
    this.setState({ loadSaveBanette: true })
    if (this.state.search.nameSearch != "En cours" && this.state.search.nameSearch != "Différé") {
      axios.post(Store.wsPath + '/1/workflows/saveSearch', {
        search: this.state.search,
        choiceProject: this.state.choiceProject,
        choiceProjectStr: this.state.choiceProjectStr,
        choiceTask: this.state.choiceTask,
        choiceCircuit: this.state.choiceCircuit,
        choiceUser: this.state.choiceUser,
        type: this.state.valueChoiceProjectTask != "" ? this.state.valueChoiceProjectTask : "project",
        idUser: this.state.idUser,
        isPublic: this.state.isPublic
      }).then((response) => {
        this.setState({
          openModal: false,
          idCurrentBannette: response.data.id
        })
        this.getAllBanette();
      })
    } else {
      Store.snackBar.open = true
      Store.snackBar.error = true
      Store.snackBar.message = "Le nom de la recherche existe déjà"
    }
  }
  public openContextualMenu = async (search: IAllBannetteAndContentWS) => {
    const idsWf: any[] = [];
    const idsForms:any[]=[];
    if (search.field && search.field.idWf) {
      for (let i = 0; i < search.field.idWf.length; i++) {
        idsWf.push(search.field.idWf[i].value)
      }
    }
    if (search.field && search.field.idForms) {
      for (let i = 0; i < search.field.idForms.length; i++) {
        idsForms.push(search.field.idForms[i].value)
      }
    }
    let tempSearch: any = search.field;
    tempSearch.id = search.id;
    tempSearch.nameSearch = search.name;

    this.setState({
      choiceProject: search.field.choiceProject,
      choiceProjectStr: search.field.choiceProjectStr,
      choiceTask: search.field.choiceTask,
      choiceUser: search.field.choiceUser,
      choiceCircuit: idsWf,
      choiceForms:idsForms,
      search: tempSearch,
      openModal: true,
      isDisplayingProject: search.type == undefined || search.type == "project" ? true : false,
      valueChoiceProjectTask: search.type == undefined ? "project" : search.type,
      isPublic: search.isPublic,
      displayMoreCriters: false
    })
  }

  public onDeleteBanette = (row) => { // OUvre la modal pour delete la banette
    const idBanette = row.id;
    this.setState({
      idBanette: idBanette,
      openDeleteBanette: true
    })
  }
  closeDeleteBanette = () => { // Ferme la model pour supprimer la banette
    this.setState({
      openDeleteBanette: false,
      loadDeleteBanette: false
    })
  }
  deleteBanette = () => { // Supprime la bannette selectionnée(par onDeleteBanette)
    this.setState({ loadDeleteBanette: true });
    axios.post(Store.wsPath + '/1/workflows/deleteBanette', {
      id: this.state.idBanette
    }).then((response) => {
      this.getAllBanette();
      this.setState({
        openDeleteBanette: false,
        loadDeleteBanette: false
      })
    }).catch(error => {
      console.error('Error "deleteBanette" in searchWorkflow : ', error);
      this.setState({
        openDeleteBanette: false,
        loadDeleteBanette: false
      })
    })
  }
  public onchangeOnglet = (event: React.ChangeEvent<{}>, newValue: number) => {
    this.setState({ ongletSelect: newValue });
  }

  // public onSelect = (row) => {
  //   const bannette = (this.state.idCurrentBannette != undefined) ? "&b=" + this.state.idCurrentBannette : '';
  //   const step = (row.stepId != undefined) ? "&s=" + row.stepId : '';
  //   const task = (row.idTask != undefined) ? "&t=" + row.idTask : '';
  //   this.props.history.push("/document?w=" + row.idWf + step + task + bannette)
  // }

  public onChangeValue = (event) => {
    this.setState({
      valueChoiceProjectTask: event.target.value,
    });
  }
  public onChangeIsPublic = (event) => {
    this.setState({
      isPublic: event.target.checked
    })
  }
  public onChangeIncludeAchived= (event) => {
    this.setState({
      includeArchived: event.target.checked
    })
  }
  public onDisplayBanette = (row) => {
    axios.post(Store.wsPath + '/1/workflows/displayBanette', {
      id: row.id
    }).then((response) => {
      const temp = this.state.allBannetteAndContent.map(banette => {
        if (banette.id == row.id) {
          banette.displayBanette = !banette.displayBanette;
        }
        return banette;
      })
      this.setState({ allBannetteAndContent: temp });
    }).catch(error => {
      Store.snackBar = {
        open: true,
        error: true,
        message: "Une erreur est survenue dans l'édition de la banette."
      };
      console.error("Error in 'onDisplayBanette' in searchWorkflow : ", error);
    })
  }

  public openModalSearch = () => {
    this.setState({
      search: {},
      choiceProject: [],
      choiceTask: [],
      choiceUser: [],
      choiceCircuit: [],
      choiceForms:[],
      openModal: true,
      isNewSearch: false,
      isPublic: false,
      includeArchived:false,
      displayMoreCriters: false
    })
  }
  public onCancelSaveSearch = () => {
    this.setState({
      openNameSearch: false
    })
  }
  public onDisplayCriters = () => {
    this.setState({
      displayMoreCriters: !this.state.displayMoreCriters
    })
  }
  public nameFormatter = (cell, row) => {
    const bannette = (this.state.idCurrentBannette != undefined) ? "&b=" + this.state.idCurrentBannette : '';
    const step = (row.stepId != undefined) ? "&s=" + row.stepId : '';
    const task = (row.idTask != undefined) ? "&t=" + row.idTask : '';
    const url = "/document?w=" + row.idWf + step + task + bannette;
    return `<a href="${url}"><span title="${cell}"> ${cell}</span></a>`;
  }
  public stateFormatter = (cell, row) => {
    let stateTask = cell;
    return `<span title="${stateTask}"> ${stateTask}</span>`;
  }
  public actorFormatter = (cell, row) => {
    if (row.acteurTask.isGroup) {
      if(row.editedGroup != null && row.editedGroup != undefined){
        return (
          <span title={row.emailActeur} className={styles.showGroup}>
            {row.acteurName}
            <div className={styles.groupIcon}><PopoverGroup row={{ id: row.acteurTask.id, usersOfGroup: row.editedGroup, firstname: row.acteurTask.firstname, lastname: row.acteurTask.lastname }} mode="read" /></div>
          </span>
        );
      } else {
        return (
          <span title={row.emailActeur} className={styles.showGroup}>
            {row.acteurName}
            <div className={styles.groupIcon}><PopoverGroup row={cell} mode="read" /></div>
          </span>
        );
      }
    } else {
      return (
        <span title={row.emailActeur}>
          {row.acteurName}
        </span>
      );
    }
  }
  public toolboxSearchResult = (cell: string, row) => {
    let component = <React.Fragment></React.Fragment>
    if (Store.userConnected.typeUserLydoc == "ged" || Store.userConnected.typeUserLydoc == "admin" ){
      component = (
        <React.Fragment>
          <span title="Supprimer le circuit"><Delete className={styles.toolboxIcon} onClick={this.openCloseDeleteWFModal(true, row.idWf)} /></span>
        </React.Fragment>
      )
    }else{
      component = (
        <React.Fragment>
        </React.Fragment>
      )
    }
    
    return (
      <div className={styles.toolboxContainer}>
        {component}
      </div>
    )
  }
  public setIsNewSearch = () => {
    this.setState({
      isNewSearch: false
    });
  }
  // public onPageChange(page, sizePerPage) {
  //   const multiOffset = page-1;
  //   const offset = 10*multiOffset;
  //   if(this.state.search && this.state.search.id == undefined){
  //     this.onClickDisplayTabFiltered(offset);
  //   }
    
  // }
  public render() {
    const selectRow = {
      mode: 'radio',
      clickToSelect: true,
      hideSelectColumn: true
    }

    const options = {
      sortIndicator: true,
      defaultSortName: 'title',
      noDataText: 'Aucune tâche à afficher',
      sizePerPage: 25,
      // onPageChange: this.onPageChange.bind(this)
    };
    let listDisplayByType: IWSReturnResultSearchFilterRow[] = [];
    if (this.state.isDisplayingProject) {
      for (let index = 0; index < this.state.listDisplaying.length; index++) {
        const element = this.state.listDisplaying[index];
        if (listDisplayByType.find(ld => ld.idWf == element.idWf) == undefined) {
          listDisplayByType.push(element);
        }
      }
    } else {
      listDisplayByType = this.state.listDisplaying
      listDisplayByType.forEach(ldt => {
        let newName = ""
        if (ldt.acteurTask.isGroup) {
          let userIsIn: boolean = false;
          if(ldt.editedGroup != null && ldt.editedGroup != undefined){
            userIsIn = ldt.editedGroup.find(userOfGroup => Store.userConnected.id == userOfGroup) != undefined;
          } else {
            userIsIn = ldt.acteurTask.usersOfGroup.find(userOfGroup => Store.userConnected.id == userOfGroup) != undefined;
          }
          if (userIsIn) {
            newName = Store.userConnected.lastname + " " + Store.userConnected.firstname;
          } else {
            newName = ldt.acteurTask.lastname + " " + ldt.acteurTask.firstname;
          }
        } else {
          newName = ldt.acteurTask.lastname + " " + ldt.acteurTask.firstname;
        }
        ldt['acteurName'] = newName;
      })
    }
    const optionsTypeDisplay = [{value:"project",label:"Projects"},{value:"task",label:"Tâches"}];
    return (
      <div>
        {
          this.state.allBannetteAndContent.length > 0 ?
            <Banettes setIsNewSearch={this.setIsNewSearch} onSearch={this.getAllTasksCb} allBannetteAndContent={this.state.allBannetteAndContent} editBanet={this.openContextualMenu} deleteBanette={this.onDeleteBanette} displayBanette={this.onDisplayBanette} paramGestion={true} />
            : <React.Fragment></React.Fragment>
        }
        <div className={styles.secondContent}>
          <div>
            <div style={{display:"flex", justifyContent:"space-between"}}>
              <div>
                <Button
                  onClick={this.openModalSearch}
                  variant="contained"
                  color="secondary"
                >
                  Nouvelle recherche
                </Button>

                {this.state.isNewSearch == true ?
                  <Button
                    onClick={this.openNameSearch}
                    className={styles.btValide}
                    variant="contained"
                    color="secondary"
                  >
                    Sauvegarder
                  </Button>
                : <React.Fragment></React.Fragment>}
              </div>
              
              <div onClick = {()=>{this.updateBanette(this.state.allReadyRefresh)}} style={this.state.allReadyRefresh == true ?{display:"flex",color:"grey"}:{display:"flex",cursor:"pointer",color:"black"}}>
                <p>{this.state.textRefresh}</p>
                {this.state.allReadyRefresh == false?
                  <Icon style={{fontSize:"17px",marginTop:"3px"}} className={styles.icon}  title="Mettre à jour">refresh</Icon>
                  :<React.Fragment></React.Fragment>
                }
              </div>
            </div>
            <Dialog
              open={this.state.openNameSearch}
              scroll="body"
              className={styles.saveSearchModal}
            >
              <DialogTitle>Sauvegarder</DialogTitle>
              <div className={styles.modalSearchName}>
                <TextField
                  variant='outlined'
                  id="date"
                  label="Saisir le nom de la recherche"
                  type="text"
                  InputLabelProps={{ shrink: true }}
                  onChange={this.onChange("nameSearch")}
                  className={styles.nameInput}
                />
                {Store.userConnected.typeUserLydoc === "ged" || Store.userConnected.typeUserLydoc === "admin" ?
                  <div className={styles.typeSearch}>
                    <FormLabel className={styles.typeSearchLabel}>Type de recherche : </FormLabel>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={this.state.isPublic}
                          onChange={this.onChangeIsPublic}
                          color="primary"
                        />
                      }
                      label="Publique"
                    />
                  </div>
                  :
                  <React.Fragment></React.Fragment>
                }
                <div className={styles.contentButton}>
                  <Button
                    onClick={this.onCancelSaveSearch}
                    variant="contained"
                    color="secondary"
                    disabled={this.state.loadSaveBanette}
                  >
                    Annuler
                  </Button>
                  <Button
                    onClick={this.onSaveSearch}
                    className={styles.buttonValide}
                    variant="contained"
                    color="primary"
                    disabled={this.state.loadSaveBanette}
                  >
                    {this.state.loadSaveBanette ?
                      <Spinner size={24} />
                      :
                      "Valider"
                    }
                  </Button>
                </div>
              </div>
            </Dialog>
            <DialogFabric
            hidden={this.state.deleteDialogWFParams.hideDialog}
            onDismiss={this.openCloseDeleteWFModal(false)}
            dialogContentProps={{
              type: DialogType.normal,
              title: "Supprimer le circuit",
              closeButtonAriaLabel: 'Fermer',
            }}
            modalProps={{
              isBlocking: true
            }}
          >
            {
                this.state.deleteDialogWFParams.error == true ?
                  <p>Une erreur est survenue lors de la suppression du circuit</p>
                  :
                  this.state.deleteDialogWFParams.loading == true ?
                    <SpinnerFabric size={SpinnerSize.large} label="Suppression du circuit en cours" labelPosition="bottom" />
                    :
                    
                      <React.Fragment>
                        <p>Vous allez supprimer un circuit, toutes les données citées ci dessous seront supprimées :</p>
                        <ul>
                          <li>Circuit</li>
                          <li>Tâches</li>
                          <li>Documents joints</li>
                          <li>Post-it</li>
                        </ul>
                        <DialogFooter>
                          <PrimaryButton onClick={this.deleteWF(this.state.selectedWFToDelete)} text="Confirmer" />
                          <DefaultButton onClick={this.openCloseDeleteWFModal(false)} text="Annuler" />
                        </DialogFooter>
                      </React.Fragment>
            }
          </DialogFabric>
            {listDisplayByType.length != 0 ?
              <div className={styles.listDisplay}>

                {this.state.isDisplayingProject == true ?
                  <div className={styles.results}>{listDisplayByType.length > 1 ? this.state.totalRows + " circuits correspondent à la recherche" : this.state.totalRows + " circuit correspond à la recherche"}</div>
                  :""
                }
                {this.state.isDisplayingProject == false ?
                  <BootstrapTable data={listDisplayByType} keyField='uniqueIdString' searchPlaceholder='Rechercher dans la liste' search={true} options={options}
                    selectRow={selectRow}
                    pagination
                    trClassName={styles.trBootstrapTable}>
                    <TableHeaderColumn dataField='uniqueIdString' hidden >id</TableHeaderColumn>
                    <TableHeaderColumn dataField='nameWf' dataSort={true} dataFormat={this.nameFormatter} >Nom du dossier</TableHeaderColumn>
                    <TableHeaderColumn dataField='stateWf'>Etat du circuit</TableHeaderColumn>
                    <TableHeaderColumn dataField='nameTask'>Nom de la tâche</TableHeaderColumn>
                    <TableHeaderColumn dataField='stateTask' dataFormat={this.stateFormatter}>Etat de la tâche</TableHeaderColumn>
                    <TableHeaderColumn dataField='acteurTask' dataFormat={this.actorFormatter}>Acteur de la tâche</TableHeaderColumn>
                    <TableHeaderColumn dataField="acteurName" hidden>Acteur nom</TableHeaderColumn>

                  </BootstrapTable>
                  :
                  <BootstrapTable data={listDisplayByType} keyField='uniqueIdString' searchPlaceholder='Rechercher dans la liste' search={true} options={options}
                    selectRow={selectRow}
                    pagination
                    trClassName={styles.trBootstrapTable}>
                    <TableHeaderColumn dataField='uniqueIdString' hidden >id</TableHeaderColumn>
                    <TableHeaderColumn dataField='nameWf' dataSort={true} dataFormat={this.nameFormatter}>Nom du dossier</TableHeaderColumn>
                    <TableHeaderColumn dataField='stateWf'>Etat du circuit</TableHeaderColumn>
                    <TableHeaderColumn width='100px' tdStyle={{ padding: 0 }} dataField='name' dataAlign='center' dataFormat={this.toolboxSearchResult} editable={false}>Actions</TableHeaderColumn>
                  </BootstrapTable>
                }
              </div>
              : this.state.loadTable ?
                <Spinner size={32} label="Récupération des données..." />
                :
                <div className={styles.defaultText}>Aucun élément correspond à votre recherche</div>
            }
          </div>
        </div>
        <Dialog
          open={this.state.openModal}
          scroll="body"
          fullWidth
        // maxWidth = "xl"
        // className={modal.modal}
        >
          <div>
            <h4 className={styles.formTitle}>{this.state.search && this.state.search.id != undefined ? "Édition recherche : " + this.state.search.nameSearch : "Nouvelle Recherche"}</h4>
            
            <div className={styles.contentModal}>
              <FormLabel className={styles.formLabel}>Type de résultat</FormLabel>
              <SelectReact
                options={optionsTypeDisplay}
                onChange={option => this.onChangeSelect(option ? option : null, "typeDisplay")}
                placeholder="Type de résultat"
                styles={{ control: styles => ({ ...styles, backgroundColor: 'transparent', padding: '2px 0' }), menu: base => ({ ...base, zIndex: 99999 }) }}
                // value={this.state.idsChoice != undefined ? this.updateReactSelectState(this.state.idsChoice) : null}
                value={optionsTypeDisplay.filter(o => o.value == this.state.valueChoiceProjectTask)[0]}
              />
              <div className={styles.typePublic}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={this.state.includeArchived}
                      onChange={this.onChangeIncludeAchived}
                      color="primary"
                    />
                  }
                  label="Inclure les archives (circuits Refusés ou Terminés il y a plus de 30 jours)"
                />
              </div>
            </div>
            
            {this.state.search && this.state.search.id != undefined && (Store.userConnected.typeUserLydoc === "ged" || Store.userConnected.typeUserLydoc === "admin") ?
              <div className={styles.typePublic}>
                <FormLabel className={styles.formLabel}>Type de recherche</FormLabel>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={this.state.isPublic}
                      onChange={this.onChangeIsPublic}
                      color="primary"
                    />
                  }
                  label="Public"
                />
              </div>
              :
              <React.Fragment></React.Fragment>
            }
            <Typography variant="h5" id="modal-title">
              <span className={styles.modalTitle}>Affiner la recherche</span>
            </Typography>
            <br />
            <Typography variant="subtitle2" id="simple-modal-description">
              <div className={styles.contentModal}>
                <SelectReact
                  options={tabStateProject}
                  // components = {option => this.animated(option ? option : null)}
                  onChange={option => this.onChangeSelect(option ? option : null, "prj")}
                  placeholder="Etat(s) du projet"
                  filterOption={createFilter({ ignoreAccents: false })}
                  // className={styles.field}
                  styles={{ control: styles => ({ ...styles, backgroundColor: 'transparent', padding: '2px 0' }), menu: base => ({ ...base, zIndex: 99999 }) }}
                  isClearable
                  isMulti
                  // value={this.state.idsChoice != undefined ? this.updateReactSelectState(this.state.idsChoice) : null}
                  value={this.state.choiceProject != undefined && this.state.choiceProject.length > 0 ? this.updateReactSelect(this.state.choiceProject, "projet") : null}
                />
              </div>

              <div className={styles.contentModal}>
                <SelectReact
                  options={this.state.suggestionTask}
                  // components = {option => this.animated(option ? option : null)}
                  onChange={option => this.onChangeSelect(option ? option : null, "task")}
                  placeholder="Etat(s) des tâches"
                  filterOption={createFilter({ ignoreAccents: false })}
                  // className={styles.field}
                  styles={{ control: styles => ({ ...styles, backgroundColor: 'transparent', padding: '2px 0' }), menu: base => ({ ...base, zIndex: 99999 }) }}
                  isClearable
                  isMulti
                  value={this.state.choiceTask != undefined ? this.updateReactSelect(this.state.choiceTask, "task") : null}
                />
              </div>

              <div className={styles.contentModal}>
                <SelectReact
                  options={Store.suggestionsUser}
                  onChange={option => this.onChangeSelect(option ? option : null, "user")}
                  placeholder="Acteur de la tâche"
                  filterOption={createFilter({ ignoreAccents: false })}
                  value={this.state.choiceUser != [] ? this.updateReactSelect(this.state.choiceUser, "taskuser") : []}
                  className={styles.field}
                  isMulti
                  styles={{ control: styles => ({ ...styles, backgroundColor: 'transparent', padding: '2px 0' }) }}
                  isClearable
                  components={{ MultiValueLabel: customMultipleValue, Option: MenuList }}
                />
              </div>
              <div className={styles.contentModal}>
                <SelectReact
                  options={this.state.allModelsOptions}
                  onChange={option => this.onChangeSelect(option ? option : null, "allWf")}
                  placeholder="Choisir le modèle"
                  filterOption={createFilter({ ignoreAccents: false })}
                  value={this.state.choiceCircuit != undefined && this.state.choiceCircuit.length>0 ? this.updateReactSelect(this.state.choiceCircuit, "choiceCircuit") : null}
                  className={styles.field}
                  isMulti
                  styles={{ control: styles => ({ ...styles, backgroundColor: 'transparent', padding: '2px 0' }) }}
                  isClearable
                />
              </div>
              {Store.searchForm == true?
                <div className={styles.contentModal}>
                  <SelectReact
                    options={this.state.allFormsOptions}
                    onChange={option => this.onChangeSelect(option ? option : null, "allForms")}
                    placeholder="Choisir le formulaire"
                    filterOption={createFilter({ ignoreAccents: false })}
                    value={this.state.choiceForms != [] ? this.updateReactSelect(this.state.choiceForms, "choiceForms") : []}
                    className={styles.field}
                    isMulti
                    styles={{ control: styles => ({ ...styles, backgroundColor: 'transparent', padding: '2px 0' }) }}
                    isClearable
                  />
                </div>
                :
                <React.Fragment></React.Fragment>
              }
              

              <div className={styles.contentModal}>
                <TextField
                  label="Recherche"
                  variant="outlined"
                  className={styles.groupInput +" " +styles.groupInputLabelzindex}
                  value={this.state.search.pleinText}
                  onChange={this.onChange("pleinText")}
                />
              </div>
            </Typography>
            <br />
            <div className={styles.contentButton}>
              <Button
                onClick={this.modalClose}
                className={styles.button}
                variant="contained"
                color="secondary"
                disabled={this.state.loadData}
              >
                Annuler
              </Button>
              <Button
                onClick={this.state.search && this.state.search.id == undefined ? this.onClickDisplayTabFiltered(0) : this.updateSearchSave}
                className={styles.buttonValide}
                variant="contained"
                color="primary"
                disabled={this.state.loadData}
              >
                {this.state.loadData ?
                  <Spinner size={24} />
                  :
                  "Valider"
                }
              </Button>
            </div>
          </div>
        </Dialog>
        <Dialog
          open={this.state.openDeleteBanette}
          scroll="body"
          maxWidth="sm"
        >
          <div style={{ textAlign: "center", margin: "10px" }}>
            <h4 >Suppression</h4>
            <p>Êtes vous sûr de vouloir supprimer cette banette?</p>
            <div className={styles.contentButton}>
              <Button
                onClick={this.closeDeleteBanette}
                className={styles.button}
                variant="contained"
                color="secondary"
                disabled={this.state.loadDeleteBanette}
              >
                Annuler
              </Button>
              <Button
                onClick={this.deleteBanette}
                className={styles.buttonValide}
                variant="contained"
                color="primary"
                disabled={this.state.loadDeleteBanette}
              >
                {this.state.loadDeleteBanette ?
                  <Spinner size={24} />
                  :
                  "Valider"
                }
              </Button>
            </div>
          </div>
        </Dialog>
      </div>)
  }
}

export default withRouter(Search);